import './index.css';
import React, { useState, useEffect } from 'react';
import translations from '../../translations';

function Footer(props) {
  const [year, setYear] = useState(0);

  useEffect(() => {
    let date = new Date();
    let year = date.getFullYear();
    setYear(year);
  }, []);

  return (
    <footer>
      <div className="copyright">
        © {year}, {translations[props.lang].footer.copyright}
      </div>
    </footer>
  );
}

export default Footer;
