import './App.css';
import Home from './components/Home/index';
import News from './components/News/index';
import Carrier from './components/Carrier/index';
import CarrierPost from './components/Carrier/Post';
import Footer from './components/Footer/index';
import Menu from './components/Menu/index';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React, { useState } from 'react';
import translations from './translations';

function App() {
  const [lang] = useState('lt');

  return (
    <div className="App">
      <Router>
        <Menu lang={lang} />
        <Switch>
          <Route path={`/${translations[lang].routes.news}`}>
            <News lang={lang} />
          </Route>
          <Route path={`/${translations[lang].routes.carrier}/:slug/:id`}>
            <CarrierPost lang={lang} />
          </Route>
          <Route path={`/${translations[lang].routes.carrier}`}>
            <Carrier lang={lang} />
          </Route>
          <Route path="/">
            <Home lang={lang} />
          </Route>
        </Switch>
      </Router>
      <Footer lang={lang} />
    </div>
  );
}

export default App;
