import './index.css';
import Hero from '../Hero/index';
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import translations from '../../translations';

function Home(props) {
  const [description, setDescription] = useState('');
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setDescription(
      'UAB Gricius Logistics - Darbo skelbimai ir Karjera mūsų įmonėje. Kandidatuokite tik per 1 minutę į esančias laisvas pozicijas!'
    );
    setCurrentUrl(window.location.href);
  }, []);

  return (
    <div className="page">
      <Helmet>
        <title>{`${process.env.REACT_APP_SITE_TITLE} - ${
          translations[props.lang].meta.home.title
        }`}</title>
        <meta name="description" content={description} />

        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${process.env.REACT_APP_SITE_TITLE} - ${
            translations[props.lang].meta.home.title
          }`}
        />
        <meta property="og:description" content={description} />
        <link rel="canonical" href={currentUrl} />
      </Helmet>
      <Hero lang={props.lang} />
      <div className="container mainTable innerPage innerPageText">
        <div className="home">
          <h1 className="text-center">
            {translations[props.lang].pages.home.heading}
          </h1>
          <div className="homeContainer">
            <p
              dangerouslySetInnerHTML={{
                __html: translations[props.lang].pages.home.content,
              }}
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
