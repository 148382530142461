import './Post.css';
import Hero from '../Hero/index';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import translations from '../../translations';

// ToDo: on success form message; check file size limit - 10 mb each; upload file to server; responsive version; react-helmet;

function CarrierPost(props) {
  let { id } = useParams();

  // API items
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  // Form
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [jobId, setJobId] = useState('');
  const [file, setFile] = useState([]);
  const [formError, setFormError] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [currentUrl, setCurrentUrl] = useState('');
  const [formLoading, setFormLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/jobs.php?id=${id}`, {})
      .then(res => res.json())
      .then(
        result => {
          setIsLoaded(true);
          setItems(result);
          setTitle(
            `${result[0].position} - ${process.env.REACT_APP_SITE_TITLE}`
          );
          setDescription(`${result[0].meta_description}`);
          setSubject(result[0].position);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      );

    setPhone('+3706');
    setJobId(id);
    setCurrentUrl(window.location.href);
  }, [id]);

  const handleSubmit = event => {
    event.preventDefault();
    setFormLoading(true);

    // Form data
    const formData = new FormData();
    formData.append('name', name);
    formData.append('lastName', lastName);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('id', jobId);
    if (typeof file !== 'undefined') {
      formData.append('file', file);
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/carrier_form.php`, formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(
        response => {
          let res = response.data;
          if (Boolean(res.success) === true) {
            setFormError([]);

            setName('');
            setLastName('');
            setPhone('');
            setEmail('');
            setFile([]);
            setSuccess(true);
          } else {
            setFormError(res.errors);
          }
          setFormLoading(false);
        },
        error => {
          console.log(error);
          setFormLoading(false);
        }
      );
  };

  let html;
  if (error) {
    html = (
      <div className="block text-center">
        {translations[props.lang].global.somethingWrong}
      </div>
    );
  } else if (!isLoaded) {
    html = (
      <div className="loader text-center">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    html = (
      <div>
        {items.map(item => (
          <div key={item.id}>
            <h1 className="text-center">
              {item.position}{' '}
              {translations[props.lang].pages.carrierPost.position}
            </h1>
            <div className="carrierPostContainer">
              <div className="row carrierPostInfo">
                <div className="col-md-3">
                  {translations[props.lang].pages.carrierPost.company}:{' '}
                  <strong>
                    {Number(item.company) === 0
                      ? `${translations[props.lang].global.company1}`
                      : `${translations[props.lang].global.company2}`}
                  </strong>
                </div>
                <div className="col-md-3">
                  {translations[props.lang].pages.carrierPost.city}:{' '}
                  <strong>{item.city}</strong>
                </div>
                <div className="col-md-3">
                  {translations[props.lang].pages.carrierPost.salary}:{' '}
                  <strong>
                    {translations[props.lang].global.from} {item.salary}
                    {translations[props.lang].global.euroMonth}{' '}
                    {Number(item.salary_type) === 0
                      ? `${translations[props.lang].global.netto}`
                      : `${translations[props.lang].global.brutto}`}
                  </strong>
                </div>
                <div className="col-md-3">
                  {translations[props.lang].pages.carrierPost.published}:{' '}
                  <strong>{item.created_at}</strong>
                </div>
                <div className="col-md-3">
                  {translations[props.lang].pages.carrierPost.views}:{' '}
                  <strong>{item.views}</strong>
                </div>
                <div className="col-md-3">
                  {translations[props.lang].pages.carrierPost.candidate}:{' '}
                  <strong>{item.candidate}</strong>
                </div>
              </div>
              <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
            </div>
          </div>
        ))}
        <div className="carrierForm carrierPostContainer">
          <h3>
            {translations[props.lang].pages.carrierPost.contactFormHeading}
          </h3>
          <div className="container mainTable2">
            <form onSubmit={handleSubmit} id="carrierForm">
              {success ? (
                <div class="alert alert-success text-center" role="alert">
                  {translations[props.lang].pages.carrierPost.successMessage}
                </div>
              ) : (
                ''
              )}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {translations[props.lang].pages.carrierPost.name}{' '}
                      <span>
                        {translations[props.lang].pages.carrierPost.required}
                      </span>
                    </label>
                    <input
                      name="name"
                      type="text"
                      className={`form-control ${
                        formError.name ? 'is-invalid' : ''
                      }`}
                      placeholder={
                        translations[props.lang].pages.carrierPost.inputText
                      }
                      value={name}
                      onChange={e => setName(e.target.value)}
                      disabled={formLoading}
                    />
                    {formError.name ? (
                      <div className="invalid-feedback">{formError.name}</div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {translations[props.lang].pages.carrierPost.lastName}{' '}
                      <span>
                        {translations[props.lang].pages.carrierPost.required}
                      </span>
                    </label>
                    <input
                      name="lastName"
                      type="text"
                      className={`form-control ${
                        formError.lastName ? 'is-invalid' : ''
                      }`}
                      placeholder={
                        translations[props.lang].pages.carrierPost.inputText
                      }
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                      disabled={formLoading}
                    />
                    {formError.lastName ? (
                      <div className="invalid-feedback">
                        {formError.lastName}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {translations[props.lang].pages.carrierPost.phone}{' '}
                      <span>
                        {translations[props.lang].pages.carrierPost.required}
                      </span>
                    </label>
                    <input
                      name="phone"
                      type="text"
                      className={`form-control ${
                        formError.phone ? 'is-invalid' : ''
                      }`}
                      placeholder={
                        translations[props.lang].pages.carrierPost.inputText
                      }
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                      disabled={formLoading}
                    />
                    {formError.phone ? (
                      <div className="invalid-feedback">{formError.phone}</div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {translations[props.lang].pages.carrierPost.email}{' '}
                      <span>
                        {translations[props.lang].pages.carrierPost.required}
                      </span>
                    </label>
                    <input
                      name="email"
                      type="email"
                      className={`form-control ${
                        formError.email ? 'is-invalid' : ''
                      }`}
                      placeholder={
                        translations[props.lang].pages.carrierPost.inputText
                      }
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      disabled={formLoading}
                    />
                    {formError.email ? (
                      <div className="invalid-feedback">{formError.email}</div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      <strong>
                        {translations[props.lang].pages.carrierPost.attachCv}
                      </strong>
                    </label>
                    {file && file.name ? (
                      <div className="file">
                        {
                          translations[props.lang].pages.carrierPost
                            .selectedFile
                        }
                        : <strong className="fileSuccess">{file.name}</strong>
                      </div>
                    ) : (
                      <div className="file">
                        {
                          translations[props.lang].pages.carrierPost
                            .selectedFile
                        }
                        :{' '}
                        <strong>
                          {
                            translations[props.lang].pages.carrierPost
                              .notSelectedFile
                          }
                          .
                        </strong>
                      </div>
                    )}
                    <input
                      type="file"
                      className={`form-control-file ${
                        formError.files ? 'is-invalid' : ''
                      }`}
                      id="exampleFormControlFile1"
                      onChange={event => setFile(event.target.files[0])}
                      disabled={formLoading}
                    />
                    {formError.files ? (
                      <div className="invalid-feedback">{formError.files}</div>
                    ) : (
                      ''
                    )}
                    <input
                      name="subject"
                      type="hidden"
                      className="form-control"
                      placeholder={
                        translations[props.lang].pages.carrierPost.inputText
                      }
                      defaultValue={subject}
                      readOnly
                    />
                    <input
                      name="jobId"
                      type="hidden"
                      className="form-control"
                      placeholder={
                        translations[props.lang].pages.carrierPost.inputText
                      }
                      defaultValue={jobId}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-12 text-center privacy-policy"
                  dangerouslySetInnerHTML={{
                    __html: translations[props.lang].global.privacy,
                  }}
                ></div>
              </div>
              <div className="row text-center">
                <div className="col-md-12">
                  <input
                    name="send"
                    type="submit"
                    className="btn btn-success"
                    value={
                      translations[props.lang].pages.carrierPost.sendButton
                    }
                    disabled={formLoading}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <link rel="canonical" href={currentUrl} />
      </Helmet>
      <Hero lang={props.lang} />
      <div className="container mainTable innerPage innerPageText">
        <div className="carrierPost">{html}</div>
      </div>
    </div>
  );
}

export default CarrierPost;
