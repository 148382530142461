import './index.css';
import translations from '../../translations';

function Hero(props) {
  return (
    <div className="hero">
      <div className="heroOverlay"></div>
      <img
        src={translations[props.lang].hero.img}
        alt={translations[props.lang].hero.imgAlt}
      />
      <div className="container mainTable">
        <div className="heroText">
          <h1
            dangerouslySetInnerHTML={{
              __html: translations[props.lang].hero.text,
            }}
          ></h1>
        </div>
      </div>
    </div>
  );
}

export default Hero;
