import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import translations from '../../translations';

const Menu = props => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <header>
      <div className="container mainTable">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link className="navbar-brand" to="/">
            <img
              className="logo"
              src={translations[props.lang].logo}
              alt={translations[props.lang].logoAlt}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`}
            id="navbarText"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  to="/"
                  className="nav-link"
                  activeClassName="active"
                  exact
                  onClick={handleNavCollapse}
                >
                  {translations[props.lang].header.menu.home}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={`/${translations[props.lang].routes.carrier}`}
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleNavCollapse}
                >
                  {translations[props.lang].header.menu.carrier}
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  to={`/${translations[props.lang].routes.news}`}
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleNavCollapse}
                >
                  {translations[props.lang].header.menu.news}
                </NavLink>
              </li> */}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Menu;
