import './index.css';
import Hero from '../Hero/index';
import { Helmet } from 'react-helmet';
import translations from '../../translations';

function News(props) {
  return (
    <div className="page">
      <Helmet>
        <title>{`${translations[props.lang].meta.news.title} - ${
          process.env.REACT_APP_SITE_TITLE
        }`}</title>
      </Helmet>
      <Hero lang={props.lang} />
      <div className="container mainTable innerPage">
        <div className="news">
          <h1 className="text-center">
            {translations[props.lang].pages.news.heading}
          </h1>
          <div className="newsContainer text-center">
            {translations[props.lang].pages.news.empty}
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
