import './index.css';
import Hero from '../Hero/index';
import { NavLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import translations from '../../translations';

function Carrier(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [description, setDescription] = useState('');
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/jobs.php`, {})
      .then(res => res.json())
      .then(
        result => {
          setIsLoaded(true);
          setItems(result);
        },
        error => {
          setIsLoaded(true);
          setError(error);
        }
      );

    setDescription(
      'UAB Gricius Logistics - Darbo skelbimai ir Karjera mūsų įmonėje. Kandidatuokite tik per 1 minutę į esančias laisvas pozicijas!'
    );
    setCurrentUrl(window.location.href);
  }, []);

  let html;
  if (error) {
    html = (
      <div className="block text-center">
        {translations[props.lang].global.somethingWrong}
      </div>
    );
  } else if (!isLoaded) {
    html = (
      <div className="loader text-center">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    html = (
      <div className="block">
        {items.map(item => (
          <NavLink
            to={
              '/' +
              `${translations[props.lang].routes.carrier}` +
              '/' +
              item.slug +
              '/' +
              item.id
            }
            className="row carrierSingle"
            key={item.id}
          >
            <div className="col-12 col-sm-6 col-xl-2 carrierLogo carrierAuto">
              <img
                src={translations[props.lang].logo}
                alt={translations[props.lang].logoAlt}
                className="carrierImg"
              />
            </div>
            <div className="col-12 col-sm-6 col-xl-6 carrierSingleTitle carrierAuto">
              <h2>{item.position}</h2>
              <p>
                {Number(item.company) === 0
                  ? `${translations[props.lang].global.company1}`
                  : `${translations[props.lang].global.company2}`}
              </p>
            </div>
            <div className="col-12 col-sm-6 col-xl-2 carrierAuto">
              <span
                className={
                  Number(item.salary_type) === 0
                    ? 'carrierSalary'
                    : 'carrierSalary2'
                }
              >
                <strong>
                  {translations[props.lang].global.from} {item.salary}
                </strong>
                {translations[props.lang].global.euroMonth}
                <br />
                <span>
                  {Number(item.salary_type) === 0
                    ? `${translations[props.lang].global.netto}`
                    : `${translations[props.lang].global.brutto}`}
                </span>
              </span>
            </div>
            <div className="col-12 col-sm-6 col-xl-2 carrierCity carrierAuto">
              {item.city}
            </div>
          </NavLink>
        ))}
      </div>
    );
  }

  return (
    <div className="page">
      <Helmet>
        <title>{`${translations[props.lang].meta.carrier.title} - ${
          process.env.REACT_APP_SITE_TITLE
        }`}</title>
        <meta name="description" content={description} />

        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${translations[props.lang].meta.carrier.title} - ${
            process.env.REACT_APP_SITE_TITLE
          }`}
        />
        <meta property="og:description" content={description} />
        <link rel="canonical" href={currentUrl} />
      </Helmet>
      <Hero lang={props.lang} />
      <div className="container mainTable innerPage">
        <div className="carrier">
          <h1 className="text-center">
            {translations[props.lang].pages.carrier.heading}
          </h1>
          <div className="row carrierContainer">{html}</div>
        </div>
      </div>
    </div>
  );
}

export default Carrier;
